import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import { Link } from 'gatsby';
import Image from '../components/Image';

const ComingSoon = () => (
  <Layout title="Coming Soon">
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-orange"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="../../annual-challenge/about-the-challenge/">Home</Link>
          </li>
          <li className="breadcrumb active">Coming Soon</li>
        </ul>
        <h1 className="page-title">Coming Soon</h1>
        <Image filename="hero-about-challenge.png" className="hero-image" />
      </div>
    </div>
    {/* Coming Soon Intro */}
    <Section>
      <Container>
        <Row>
          <Column size={8} offset={2}>
            <h2>
              The page you were trying to visit is coming soon. Don't be sad : (
            </h2>
            <h4 className="global-intro-text">
              This page or resource is either in active development or the
              resource has yet to be determined.
              <Link to="/"> Take me home!</Link>
            </h4>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default ComingSoon;
